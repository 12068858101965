class Config {

    static classes = [
        'Paladin', 'Warrior', 'Dark Knight',
        'Dragoon', 'Monk', 'Ninja', 'Samurai',
        'Black Mage', 'Summoner', 'Red Mage',
        'Monk', 'Machinist',
        'White Mage', 'Scholar', 'Astrologian'
    ];

    static fights = [
        'Alphascape V1.0 (Savage)',
        'Alphascape V2.0 (Savage)',
        'Alphascape V3.0 (Savage)',
        'Alphascape V4.0 (Savage)',
        'Hells Kier (Extreme)',
        'Alphascape Normal',
    ];

    static dummyHealth = {
        'Alphascape V1.0 (Savage)': {
            'Monk': 1049392,
            'Dragoon': 986417,
            'Ninja': 101275,
            'Samurai': 1095192,
            'Bard': 930885,
            'Machinist': 954930,
            'Black Mage': 1189655,
            'Summoner': 1094620,
            'Red Mage': 1066567,
            'Paladin': 707037,
            'Warrior': 769440,
            'Dark Knight': 708182,
            'White Mage': 688145,
            'Scholar': 620017,
            'Astrologian': 573072
        },
        'Alphascape V2.0 (Savage)': {
            'Monk': 1084315,
            'Dragoon': 1019050,
            'Ninja': 1046530,
            'Samurai': 1131260,
            'Bard': 961800,
            'Machinist': 986417,
            'Black Mage': 1229157,
            'Summoner': 1131260,
            'Red Mage': 1022062,
            'Paladin': 729937,
            'Warrior': 794057,
            'Dark Knight': 731082,
            'White Mage': 711045,
            'Scholar': 640627,
            'Astrologian': 592537,
        },
        'Alphascape V3.0 (Savage)': {
            'Astrologian': 611430,
            'Bard': 992715,
            'Black Mage': 1268660,
            'Dark Knight': 753410,
            'Dragoon': 1051682,
            'Machinist': 1018477,
            'Monk': 1119237,
            'Ninja': 1080307,
            'Paladin': 752265,
            'Red Mage': 1136985,
            'Samurai': 1167327,
            'Scholar': 661237,
            'Summoner': 1167427,
            'Warrior': 818675,
            'White Mage': 733945,            
        },
        'Alphascape V4.0 (Savage)': {
            'Astrologian': 649215,
            'Bard': 1053972,
            'Black Mage': 1347092,
            'Dark Knight': 797492,
            'Dragoon': 1116375,
            'Machinist': 1081452,
            'Monk': 1187937,
            'Ninja': 1146717,
            'Paladin': 796347,
            'Red Mage': 1207402,
            'Samurai': 1240035,
            'Scholar': 701885,
            'Summoner': 1239462,
            'Warrior': 866765,
            'White Mage': 779172,
        },
        'Hells Kier (Extreme)': {
            'Astrologian': 557042,
            'Bard': 905122,
            'Black Mage': 1156450,
            'Dark Knight': 689290,
            'Dragoon': 958365,
            'Machinist': 928002,
            'Monk': 1020195,
            'Ninja': 984700,
            'Paladin': 688145,
            'Red Mage': 1036797,
            'Samurai': 1064277,
            'Scholar': 602842,
            'Summoner': 1063705,
            'Warrior': 744830,
            'White Mage': 668680,
        },
        'Alphascape Normal': {
            'Astrologian': 506090,
            'Bard': 822110,
            'Black Mage': 1049965,
            'Dark Knight': 589102,
            'Dragoon': 870772,
            'Machinist': 842720,
            'Monk': 926305,
            'Ninja': 824245,
            'Paladin': 587957,
            'Red Mage': 941190,
            'Samurai': 966380,
            'Scholar': 547310,
            'Summoner': 966380,
            'Warrior': 640055,
            'White Mage': 607422,
        }
    }
}
 
export default Config;