import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import Config from './Config';
import './App.css';

class App extends Component {

    constructor() {
        super();
        
        this.state = {
            selectedClass: 'Dragoon',
            selectedDuty: 'Alphascape V1.0 (Savage)',
            remainingHp: 0,
            remainingSeconds: 0
        };

        this.handleClassChanged = this.handleClassChanged.bind(this);
        this.handleDutyChanged = this.handleDutyChanged.bind(this);
        this.handleRemainingHpChanged = this.handleRemainingHpChanged.bind(this);
        this.handleRemainingSecondsChanged = this.handleRemainingSecondsChanged.bind(this);
    }

    handleClassChanged(event) {
        this.setState({selectedClass: event.target.value});
    }

    handleDutyChanged(event) {
        this.setState({selectedDuty: event.target.value});
    }

    handleRemainingHpChanged(event) {
        this.setState({remainingHp: event.target.value});
    }

    handleRemainingSecondsChanged(event) {
        this.setState({remainingSeconds: event.target.value});
    }

    render() {
        let classes = Config.classes.map((cls, index) => <option key={index} value={cls}>{cls}</option>);
        let duties = Config.fights.map((duty, index) => <option key={index} value={duty}>{duty}</option>);

        const dummyHealth = Config.dummyHealth[this.state.selectedDuty][this.state.selectedClass];
        const result = this.calculate(dummyHealth);

        return (
            <div className="app-container">
                <h1><small>Very Ugly</small> Stone, Sky, Sea DPS Estimator <small>4.4 only</small></h1>

                <div class="section">
                    <div className="label">Class:</div>
                    <select value={this.state.selectedClass} onChange={this.handleClassChanged}>
                        {classes}
                    </select>
                </div>

                <div class="section">
                    <div className="label">Fight:</div>
                    <select value={this.state.selectedDuty} onChange={this.handleDutyChanged}>
                        {duties}
                    </select>
                </div>

                <div class="section">
                    <div className="label">Remaining HP %</div>
                    <input type="number" min="0" max="100" value={this.state.remainingHp} onChange={this.handleRemainingHpChanged} />
                </div>

                <div class="section">
                    <div className="label">Remaining Seconds</div>
                    <input type="number" min="0" max="179" value={this.state.remainingSeconds} onChange={this.handleRemainingSecondsChanged} />
                </div>

                <h2>Your DPS is approximately <NumberFormat value={result} displayType={'text'} thousandSeparator={true} decimalScale={2}/></h2>
            </div>
        );
    }

    calculate(dummyHealth) {
        return (1 - (this.state.remainingHp * 0.01)) * dummyHealth / (180 - this.state.remainingSeconds);
    }
}
 
export default App;